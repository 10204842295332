// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blanche-myrtille-index-mdx": () => import("./../src/pages/blancheMyrtille/index.mdx" /* webpackChunkName: "component---src-pages-blanche-myrtille-index-mdx" */),
  "component---src-pages-diy-index-mdx": () => import("./../src/pages/diy/index.mdx" /* webpackChunkName: "component---src-pages-diy-index-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-les-basiques-index-mdx": () => import("./../src/pages/lesBasiques/index.mdx" /* webpackChunkName: "component---src-pages-les-basiques-index-mdx" */),
  "component---src-pages-plaato-index-mdx": () => import("./../src/pages/plaato/index.mdx" /* webpackChunkName: "component---src-pages-plaato-index-mdx" */),
  "component---src-pages-premier-post-index-mdx": () => import("./../src/pages/premierPost/index.mdx" /* webpackChunkName: "component---src-pages-premier-post-index-mdx" */),
  "component---src-pages-starter-episode-1-index-mdx": () => import("./../src/pages/starter-episode1/index.mdx" /* webpackChunkName: "component---src-pages-starter-episode-1-index-mdx" */)
}

